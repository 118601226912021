import type { MouseEventHandler, PropsWithChildren } from 'react'
import { theme } from '../../../styles'
export interface IButton extends PropsWithChildren {
  backgroundColor?: string
  backgroundColorHover?: string
  href?: string
  isDisabled?: boolean
  isWidthAuto?: boolean
  onClick?: MouseEventHandler<HTMLAnchorElement> & MouseEventHandler<HTMLButtonElement>
  textColor?: string
  type?: 'button' | 'submit' | 'reset'
  testId?: string
  target?: string
}

export function Button({
  backgroundColor,
  backgroundColorHover,
  children,
  href,
  isDisabled,
  isWidthAuto,
  onClick,
  textColor,
  type,
  testId,
  target,
}: IButton): JSX.Element {
  const RenderComponent = href ? 'a' : 'button'
  const linkTarget = href ? target : undefined

  return (
    <>
      <RenderComponent
        className={
          'Button flex justify-center items-center width-100' +
          (isWidthAuto ? ` Button--WidthAuto` : '')
        }
        href={href ? href : undefined}
        type={href ? undefined : type || 'button'}
        onClick={onClick}
        disabled={isDisabled}
        data-testid={testId}
        target={linkTarget}
      >
        {children}
        {linkTarget === '_blank' ? <span className="sr-only"> (nouvelle fenêtre)</span> : null}
      </RenderComponent>

      <style jsx>{`
        .Button {
          background: ${backgroundColor || theme.cssVars.deepBlue};
        }
        .Button {
          color: ${textColor || theme.cssVars.white};
        }

        .Button:hover {
          background: ${backgroundColorHover || backgroundColor || theme.cssVars.deepBlue};
        }
      `}</style>
      <style jsx>{`
        .Button {
          cursor: pointer;
          border: none;
          text-decoration: none;

          height: 56px;

          padding: 16px 24px;

          border-radius: 4px;
          filter: brightness(1);
          transition: all 200ms ease-in;

          font-style: normal;
          font-size: 15px;
          font-weight: 700;
          line-height: 141%;
        }

        .Button--WidthAuto {
          width: auto;
        }

        .Button:disabled,
        .Button[disabled] {
          cursor: not-allowed;
          background: var(--color-gray);
        }

        :where(.Button:hover, .Button:focus) :not(.Button[disabled], .Button:disabled) {
          filter: brightness(1.5);
        }
      `}</style>
    </>
  )
}
