import type { ReactNode, ReactElement } from 'react'
import type { IPage } from '../../../types/page'
import Head from 'next/head'
import Script from 'next/script'
import { useAmp } from 'next/amp'
import { TagCommander } from '../../../components/atoms/TagCommander'
import { AdConfig } from '../../../components/atoms/AdConfig/AdConfig'
import { ScriptProbe } from '../../../components/atoms/ScriptProbe'
import { ScriptProbeTeads } from '../../atoms/ScriptProbeTeads'
import { ScriptProbeBatch } from '../../atoms/ScriptProbeBatch'
import { ScriptProbeBraze } from '../../atoms/ScriptProbeBraze'
import { ScriptProbeOwnPage } from '../../atoms/ScriptProbeOwnPage'
import { ScriptProbeNonli } from '../../atoms/ScriptProbeNonli'
import { ApiResponse } from '../../../contexts/ApiResponseContext'
import { ExternalScripts } from '../../../contexts/ExternalScripts'
import { GigyaAuth } from '../../../contexts/GigyaAuth'
import { ModalProviderWrapper } from '../../../contexts/ModalContext'
import { NotificationsProvider } from '../../../contexts/NotificationsContext'
import { PlayerFullScreenContextProvider } from '../../../contexts/PlayerFullScreenContext'
import { ToastProviderWrapper } from '../../../contexts/ToastContext'
import { PlayerManagerContextWrapper } from '../../../contexts/playerManager'
import { GAMCookieProbe } from '../../atoms/GAMCookieProbe'
import { SEOLayout } from '../SEOLayout'
import { POOOL_AUDIT_SCRIPT_ID, PUBSTACK_SCRIPT_ID } from '../../../constants/scripts'
import scripts from '../../../vendors'
import { fontStyle, globalStyle } from '../../../styles'

interface IPageLayout {
  children: ReactElement | ReactNode
  pageProps: IPage
}

export function PageLayout({ children, pageProps }: IPageLayout): JSX.Element {
  const isAmp = useAmp()
  return (
    <>
      <SEOLayout page={pageProps.page} type={pageProps.type} subtype={pageProps.subtype} />
      <Head>
        {!isAmp && <meta name="viewport" content="width=device-width, initial-scale=1" />}
        {isAmp && <link rel="preconnect" href="https://cdn.ampproject.org" />}
      </Head>
      {isAmp && <Script async src="https://cdn.ampproject.org/v0/amp-ad-0.1.js" />}
      <ExternalScripts scripts={scripts}>
        <GAMCookieProbe />
        <ScriptProbe id={PUBSTACK_SCRIPT_ID} />
        <ScriptProbe id={POOOL_AUDIT_SCRIPT_ID} />
        <ScriptProbeTeads />
        <ScriptProbeNonli page={pageProps?.page} />

        <ToastProviderWrapper type={pageProps?.type}>
          <GigyaAuth>
            <ScriptProbeOwnPage />
            <ScriptProbeBatch wordings={pageProps?.page?.config?.notifications} />
            <ScriptProbeBraze />
            <AdConfig config={pageProps?.page?.ads} />
            <TagCommander tms={pageProps?.page?.tms}>
              <ModalProviderWrapper>
                <PlayerManagerContextWrapper>
                  <ApiResponse
                    page={pageProps?.page}
                    subtype={pageProps?.subtype}
                    type={pageProps?.type}
                    ssr={pageProps?.ssr}
                  >
                    <NotificationsProvider>
                      <PlayerFullScreenContextProvider>{children}</PlayerFullScreenContextProvider>
                    </NotificationsProvider>
                  </ApiResponse>
                </PlayerManagerContextWrapper>
              </ModalProviderWrapper>
            </TagCommander>
          </GigyaAuth>
        </ToastProviderWrapper>
      </ExternalScripts>
      <style jsx global>
        {fontStyle}
      </style>
      <style jsx global>
        {globalStyle}
      </style>
    </>
  )
}
