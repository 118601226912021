import { useId } from 'react'

interface ITF1InfoLogoProps {
  theme?: 'white' | 'blue'
  size?: number
  height?: number
  withoutPadding?: boolean
}

export function TF1InfoLogo({
  theme = 'white',
  size = 130,
  height,
  withoutPadding,
  ...rest
}: ITF1InfoLogoProps & React.ComponentProps<'svg'>): JSX.Element {
  const id = useId()
  const SVGID = `prefix__SVGID_${id}`
  const ratio = withoutPadding ? 880 / 150 : 1024 / 314
  return (
    <svg
      x={0}
      y={0}
      viewBox={withoutPadding ? '82 82 880 150' : '0 0 1024 314'}
      width={height ? height * ratio : size}
      height={height || size / ratio}
      aria-hidden="true"
      focusable="false"
      {...rest}
    >
      <linearGradient id={id} gradientUnits="userSpaceOnUse" x1={82} y1={157} x2={492.4} y2={157}>
        <stop offset={0} stopColor="#172aef" />
        <stop offset={0.445} stopColor="#450588" />
        <stop offset={0.534} stopColor="#730262" />
        <stop offset={1} stopColor="#db0100" />
      </linearGradient>
      <path d="M82 232.2V81.8h410.4v150.4H82z" fill={`url(#${id})`} />
      <radialGradient
        id={SVGID}
        cx={287.2}
        cy={190.87}
        r={209.714}
        gradientTransform="matrix(1 0 0 -1.023 0 324.21)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fff" stopOpacity={0.2} />
        <stop offset={1} stopOpacity={0.2} />
      </radialGradient>
      <path
        d="M288.6 133.5C186 145.1 82 176.1 82 176.1V81.8h410.4v38.3s-63.2-2.4-203.8 13.4z"
        fill={`url(#${SVGID})`}
      />
      <path
        d="M205.5 104v35.9h-23.8V208h-45.5v-68.1h-23.8V104h93.1zm56.5 0v104h45.2v-32.7H335v-26h-27.8V130H346v-26h-84zm138.5 13.3V208h47V104l-47 13.3z"
        fill="#fff"
      />
      <path
        d="M697 104v104h-24.5l-46.4-55.4V208H597V104h24.5l46.4 55.4V104H697zm117 22.7V104h-82v104h29.6v-35.7h31.7v-22.7h-31.7v-22.9H814zm40.2 76.3c18.7 9.3 40.8 9.3 59.5 0 26-13.5 36.1-45.5 22.6-71.5-5-9.7-12.9-17.6-22.6-22.6-18.7-9.3-40.8-9.3-59.5 0-26 13.5-36.1 45.5-22.6 71.5 5.1 9.7 13 17.6 22.6 22.6m44.1-21c-8.9 4.9-19.7 4.9-28.6 0-4.3-2.5-7.8-6.1-10.1-10.5-5-9.7-5-21.3 0-31 2.3-4.4 5.8-8 10.1-10.5 8.9-4.9 19.7-4.9 28.6 0 4.3 2.5 7.8 6.1 10.1 10.5 5 9.7 5 21.3 0 31-2.3 4.4-5.8 8-10.1 10.5m-366.2-78l-.1 104h30V104h-29.9z"
        fill={theme === 'white' ? 'var(--color-white)' : 'var(--color-deepBlue)'}
      />
    </svg>
  )
}
