import { useContext } from 'react'
import { TagCommanderContext } from '../../../contexts/tagcommander'
import { SVGIcon } from '../../atoms/SVGIcon'
import { mixins, theme } from '../../../styles'

export interface IHeaderDirectButton {
  link: string
  title: string
}

export function HeaderDirectButton({ link, title }: IHeaderDirectButton) {
  const { hit } = useContext(TagCommanderContext)
  if (!link || !title) {
    return null
  }
  function handleClick() {
    hit(
      {
        screen_clickableElementName: `header_directs`,
      },
      { isClickEvent: true },
    )
  }
  return (
    <>
      <a
        href={link}
        onClick={handleClick}
        className="HeaderDirectButton flex items-center justify-center"
        data-testid="header-btn-direct"
      >
        <SVGIcon name="play-direct" size={20} primaryColor={theme.cssVars.white} />
        <span>{title}</span>
      </a>
      <style jsx>{`
        .HeaderDirectButton {
          cursor: pointer;
          gap: 3px;
          height: 32px;
          padding: 6px 8px;
          border-radius: 24px;
          background-color: ${theme.cssVars.red};

          color: ${theme.cssVars.white};
          font-weight: 900;
          font-size: 14px;
          text-transform: uppercase;
          white-space: nowrap;
        }

        .HeaderDirectButton span {
          height: 16px;
        }

        @media ${mixins.mediaQuery.tablet} {
          .HeaderDirectButton {
            gap: 4px;
            height: 32px;
            padding: 6px 15px;

            font-size: 15px;
          }

          .HeaderDirectButton span {
            height: 17px;
          }
        }
      `}</style>
    </>
  )
}
