import { FACILITI_SCRIPT_ID } from '../../../constants/scripts'
import { useScript } from '../../../hook/useScript'

export interface IFacilitiButton {
  alt: string
  title: string
}

export function FacilitiButton({
  alt,
  title,
  ...rest
}: IFacilitiButton & React.ComponentProps<'button'>): JSX.Element {
  useScript({ id: FACILITI_SCRIPT_ID })

  return (
    <>
      <button
        className="flex items-center"
        type="button"
        data-faciliti-popin
        aria-label={`FACIL'iti : ${title || ''}`}
        {...rest}
      >
        <img width={25} height={25} alt={alt} src="/images/icons/faciliti.svg" />
        {title?.toUpperCase() || ''}
      </button>
      <style jsx>
        {`
          button {
            cursor: pointer;
            border: none;
            background-color: transparent;
            color: var(--color-white);
            padding: 0;
          }

          button > img {
            margin-right: 5px;
          }
        `}
      </style>
    </>
  )
}
