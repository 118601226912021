import { useContext, useRef } from 'react'
import { useAmp } from 'next/amp'
import { MenuContext } from '../../../contexts/MenuContext'
import { MENU_ID } from '../../../constants/components'
import { useRefocusOnModalClose } from '../../../hook/useModalAccessibility'
import { theme } from '../../../styles'

const ACCESSIBILITY_TITLE = 'Accéder aux menu secondaire et à la recherche'

export function MenuBurgerButton(): JSX.Element {
  const isAmp = useAmp()
  const { isOpen, setIsOpenMenu } = useContext(MenuContext)
  const buttonRef = useRef<HTMLLabelElement & HTMLButtonElement>()
  useRefocusOnModalClose(isOpen, buttonRef.current)

  const props = isAmp
    ? { htmlFor: MENU_ID }
    : {
        'aria-label': ACCESSIBILITY_TITLE,
        title: ACCESSIBILITY_TITLE,
        onClick: () => setIsOpenMenu(),
      }
  const Render = isAmp ? 'label' : 'button'
  return (
    <>
      <Render
        ref={buttonRef}
        className="MenuBurgerButton flex items-center justify-center"
        data-testid="header-btn-menu"
        {...props}
      >
        <svg
          height="32"
          width="32"
          viewBox="4 6 30 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          focusable="false"
        >
          <path d="M3.64587 25.293H26.9792V28.2096H3.64587V25.293Z" fill="var(--color)" />
          <path d="M12.3959 10.707H3.64587V13.6237H12.3959V10.707Z" fill="var(--secondary-color)" />
          <path d="M3.64587 18L12.3959 18V20.9167H3.64587V18Z" fill="var(--color)" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.5104 10.3424C19.4968 10.3424 17.8645 11.9747 17.8645 13.9883C17.8645 16.0018 19.4968 17.6341 21.5104 17.6341C23.5239 17.6341 25.1562 16.0018 25.1562 13.9883C25.1562 11.9747 23.5239 10.3424 21.5104 10.3424ZM14.9479 13.9883C14.9479 10.3639 17.886 7.42578 21.5104 7.42578C25.1347 7.42578 28.0729 10.3639 28.0729 13.9883C28.0729 17.6126 25.1347 20.5508 21.5104 20.5508C17.886 20.5508 14.9479 17.6126 14.9479 13.9883Z"
            fill="var(--color)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.552 16.9688L32.3854 22.8021L30.323 24.8645L24.4896 19.0311L26.552 16.9688Z"
            fill="var(--color)"
          />
        </svg>
      </Render>
      <style jsx>{`
        .MenuBurgerButton {
          --color: ${theme.cssVars.white};
          --secondary-color: ${theme.cssVars.focusBlue};
          cursor: pointer;
          position: relative;
          width: 32px;
          height: 32px;
          background-color: transparent;
          border: none;
          padding: 0;
        }
      `}</style>
    </>
  )
}
