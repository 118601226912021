import slugify from 'slugify'

type SlugifyOptions = {
  replacement?: string
  remove?: RegExp
  lower?: boolean
  strict?: boolean
  locale?: string
  trim?: boolean
}

// Prevent inconsistent slugs between server and client
export function slugifyForDom(string: string, options?: SlugifyOptions): string {
  return slugify(string, {
    lower: true,
    strict: true,
    remove: /[^\w$*_+~.()'"!\-:@]+/g,
    ...(options || {}),
  })
}
