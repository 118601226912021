import css from 'styled-jsx/css'
import { mixins, theme } from '../../../styles'

export const styles = css`
  @media ${mixins.mediaQuery.tablet} {
    .ArticleCard__Carousel_Card {
      max-width: 360px;
    }
  }

  .ArticleCard__Content {
    position: relative;
    width: 100%;
    background-color: ${theme.cssVars.deepBlue};
    overflow: hidden;
    padding: 24px;
    height: 360px;
  }

  .ArticleCard__Content :global(picture) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
  }

  .ArticleCard__Content :global(> a) {
    display: flex;
  }

  .ArticleCard__Title :global(a:before) {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: inline;
  }

  .ArticleCard__Content :global(img) {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .ArticleCard__Content_Card {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    border-radius: 10px;
  }

  .ArticleCard__Title {
    display: block;
    z-index: 1;
    margin: 0;
  }

  .ArticleCard__Title__Text {
    display: inline;
    color: ${theme.colors.white};
    font-size: 25px;
    font-weight: 800;
    line-height: 133%;
  }

  .ArticleCard__Title__DirectIcon {
    margin-right: 5px;
  }

  .ArticleCard__Title__DirectIcon :global(.Icon) {
    display: inline;
  }

  .ArticleCard__Content :global(.ArticleCard__PlayIcon) {
    z-index: 1;
    margin-bottom: 12px;
  }

  .ArticleCard__Content :global(.ArticleCard__Duration) {
    position: absolute;
    top: 24px;
    z-index: 1;
  }

  @keyframes ArticleCardLeftZoom {
    from {
      transform: scale(1) translateX(0px);
    }
    to {
      transform: scale(1.1) translateX(0px);
    }
  }

  @keyframes ArticleCardInFromBottom {
    0% {
      transform: translateY(60%);
    }
    100% {
      transform: translateY(0);
    }
  }
`
