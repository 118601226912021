export function TF1PlusLogo({
  size,
  height,
  color = 'var(--color-white)',
  withoutPadding,
}: {
  size?: number
  height?: number
  color?: string
  withoutPadding?: boolean
}): JSX.Element {
  const ratio = withoutPadding ? 935 / 216 : 1024 / 314
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={withoutPadding ? '106 106 935 216' : '0 0 1147.32 436.33'}
      width={height ? height * ratio : size}
      height={height || size / ratio}
    >
      <path
        fill={color}
        d="M106.87,106.36H300.2v74.65h-49.39v141.56h-94.55V181.01h-49.39V106.36z M387.58,322.56h93.93V254.5h57.69
	v-54.15h-57.69v-39.84h80.69v-54.15H387.58V322.56z M649.55,322.56h97.7v-216.2l-97.7,27.71V322.56z M1039.22,196h-88.35v-89.64
	l-39.97,11.34V196h-87.88v36.07h87.88v96.16l39.97-11.33v-84.83h88.35V196z"
      />
    </svg>
  )
}
