import { useId } from 'react'

interface ITF1Logo {
  id?: string
}

export function TF1Logo({ id }: ITF1Logo): JSX.Element {
  const fakeId = useId()

  return (
    <svg
      id={id}
      width="66"
      height="23"
      viewBox="0 0 66 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="tf1"
    >
      <path d="M65.2761 0H0.832031V23H65.2761V0Z" fill={`url(#${fakeId}-1)`} />
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.2761 0V6.48702C33.054 4.791 0.832031 14.5263 0.832031 14.5263V0H65.2761Z"
        fill={`url(#${fakeId}-2)`}
        fillOpacity="0.8"
      />
      <path
        d="M20.2275 3.36914V8.90838H16.4958V19.4115H9.35279V8.90838H5.62109V3.36914H20.2275ZM29.1012 3.36914V19.4115H36.1971V14.3612H40.557V10.3506H36.1971V7.38744H42.2941V3.36914H29.1012ZM50.8538 5.42534V19.4115H58.2355V3.36914L50.8538 5.42534Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id={`${fakeId}-1`}
          x1="0.812503"
          y1="11.4617"
          x2="65.2565"
          y2="11.4617"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0416C5" />
          <stop offset="0.24" stopColor="#002DC3" />
          <stop offset="0.64" stopColor="#840339" />
          <stop offset="1" stopColor="#D00606" />
        </linearGradient>
        <radialGradient
          id={`${fakeId}-2`}
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(25.7909 -0.0187798) rotate(90) scale(18.5446 44.2562)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  )
}
