import { useContext } from 'react'
import { useRouter } from 'next/router'
import { ApiResponseContext } from '../../../contexts/ApiResponseContext'
import { isPathHomeForYou } from '../../../helpers/client'
import { theme } from '../../../styles'
import {
  MAIN_HOMEFORYOU_ID,
  MAIN_ID,
  MENU_MAIN_ID,
  SEARCH_ID,
  MENU_SECONDARY_ID,
  FOOTER_ID,
  MENU_ID,
} from '../../../constants/components'

export const QuickAccess = (): JSX.Element => {
  const { asPath } = useRouter()
  const { page } = useContext(ApiResponseContext)
  const isHomeForYou = isPathHomeForYou(asPath)
  const headerSection = page?.data?.find?.((section) => section.key == 'header') as any
  const footerSection = page?.data?.find?.((section) => section.key == 'footer')?.data as any
  const hasMainMenu = page?.data?.find?.((section) => section.key == 'menu')?.data?.length > 0
  const hasSecondaryMenu =
    headerSection?.data?.find((block) => block.key == 'header-navigation')?.data?.navigation
      ?.length > 0
  const hasHeaderNav = headerSection?.data?.elementList?.length > 0
  const hasFooter = footerSection?.length > 0

  const handleOnClickAccessibilityMenu = () => {
    const menu = document.getElementById(`${MENU_ID}`) as HTMLInputElement
    if (menu && !menu?.checked) {
      menu.click()
    }
  }

  return (
    <>
      <nav role="navigation" aria-label="Accès rapide" className="QuickAccess">
        <ul id="evitement">
          <li>
            <a href={`#${isHomeForYou ? MAIN_HOMEFORYOU_ID : MAIN_ID}`}>
              Aller au contenu principal
            </a>
          </li>
          {hasHeaderNav && (
            <li>
              <a href={`#${MENU_MAIN_ID}`}>Aller au menu principal</a>
            </li>
          )}
          {hasMainMenu && (
            <li>
              <a href={`#${SEARCH_ID}`} onClick={handleOnClickAccessibilityMenu}>
                Aller à la recherche des programmes
              </a>
            </li>
          )}
          {hasSecondaryMenu && (
            <li>
              <a href={`#${MENU_SECONDARY_ID}`}>Aller au menu tf1info</a>
            </li>
          )}
          {hasFooter && (
            <li>
              <a href={`#${FOOTER_ID}`}>Aller au pied de page</a>
            </li>
          )}
        </ul>
      </nav>

      <style jsx>{`
        .QuickAccess {
          left: -999px;
          position: absolute;
          top: auto;
          width: 1px;
          height: 1px;
          overflow: hidden;
          z-index: -999;
        }

        .QuickAccess:has(a:focus) {
          left: 2px;
          top: 20px;
          width: 100%;
          height: 40px;
          z-index: 1;
        }

        .QuickAccess a {
          display: inline-block;
          width: 1px;
          height: 1px;
          overflow: hidden;
        }

        .QuickAccess ul {
          list-style: none;
          margin: 0;
          padding: 0;
        }

        .QuickAccess ul:has(a:focus) {
          position: relative;
          list-style: none;
          margin: 0;
          padding: 5px 0 0 0;
          width: 100%;
          height: 40px;
        }

        .QuickAccess li:has(a:focus) {
          position: absolute;
          top: 0;
          left: 0;
          height: max-content;
          width: 100%;
        }

        .QuickAccess a:focus {
          color: ${theme.colors.white};
          width: auto;
          height: auto;
          overflow: hidden;
          background-color: ${theme.colors.darkBlue};
          padding: 4px 8px;
          border-radius: 8px;
        }
      `}</style>
    </>
  )
}
